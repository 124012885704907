import React from 'react'
import Mailchimp from '../../../components/Integrations/IntegrationsDetails/Mailchimp'
import PageSeo from '../../../components/SEO'

function index() {
    return (
        <div>
            <PageSeo pageTitle="Mailchimp"/>
        <Mailchimp/>
        </div>
    )
}

export default index
