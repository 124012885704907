import React from "react"
import PricingNavbar from "../../Pricing/PricingNavbar/PricingNavbar"

import "../../../styles/integrations/IntegrationsDetails.css"
import mailchimp from "../../../../static/assets/mailchimp2.png"
import mailchimpMobile from "../../../../static/assets/mailchimp.png"
import InfluencerFooter from "../../LandingSubComponents/InfluencerFooter/InfluencerFooter"
function Mailchimp() {



  return (
    <div className="IntegrationsDetailsCol">
      <PricingNavbar />
      <div className="container is-fluid integrationsDetailsContainer integrationsDetailsHeaderBox">
        <div className="columns is-vcentered is-multiline is-mobile ">
          <div className="column has-text-centered-mobile is-offset-1-desktop is-4-desktop is-4-tablet is-12-mobile">
              <div className="inteDetailsImgBox ">
              <img
              src={mailchimp}
              alt="mailchimpImg"
              title="mailChimpImg"
              loading="lazy"
              className="image inteDetailsImg pr-3 is-hidden-mobile"
            />
              <img
              src={mailchimpMobile}
              alt="mailchimpImg"
              title="mailChimpImg"
              loading="lazy"
              className="image inteDetailsImg is-hidden-tablet"
            />
              </div>
        
          </div>
          <div className="column is-3-desktop is-3-tablet is-12-mobile">
              <h1 className=" has-text-centered  inteDetailsHeader has-text-black  is-size-4-mobile is-size-3-desktop is-size-4-tablet">
                  Mailchimp
              </h1>
          </div>
          <div className="column is-4-desktop  is-5-tablet is-12-mobile  has-text-right-desktop has-text-right-tablet">
              <div className="has-text-centered-mobile">
              <button className="button is-medium has-text-white inteDetailsVisitBtn">
               VISIT MAILCHIMP
              </button>
              </div>
          </div>
        </div>
      </div>
      <div className="container is-fluid integrationsDetailsContainer">
          <div className="columns is-multiline is-mobile">
            <div className="column is-6-desktop is-6-tablet is-12-mobile">
                <div className="inteDeatilsCard">
                    <h2 className="has-text-left has-text-white is-size-4 inteDetailsCardHeader">
                        What is Mailchimp?
                    </h2>
                    <p className="mt-3 inteDetailsCardInfo has-text-white is-size-6">
                    Arka is a platform for businesses to design and order eco-friendly branded and unbranded packaging online.
                    </p>
                </div>
            </div>
            <div className="column is-6-desktop is-6-tablet is-12-mobile">
                <div className="inteDeatilsCard">
                    <h2 className="has-text-left has-text-white is-size-4 inteDetailsCardHeader">
                    How Mailchimp integrates
with Influencerbit:
                    </h2>
                    <p className="mt-3 inteDetailsCardInfo has-text-white is-size-6">
                    Use Gatsby and Arka together to bring the digital and physical worlds together. With Arka's packaging, you can ask your customers right on the box to provide you with their Instagram username. Through a QR Code to a landing page - it hooks right into your Gatsby account. Contact us to learn more and be part of the pilot.
                    </p>
                </div>
            </div>
          </div>
      </div>
      <div className="mt-3">
          <InfluencerFooter/>
      </div>
    </div>
  )
}

export default Mailchimp
