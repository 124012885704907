import React, { useState } from "react"
import "../../../styles/LandingNavbar.css"
import { Link } from "gatsby"
import toast, { Toaster } from "react-hot-toast"
import logo from '../../../../static/assets/logo.png'
import useSiteMetadata from "../../SiteMetaData";

export default function PricingNavbar() {
  const [active, setActive] = useState(false)
  const {dashboardRegisterUrl} = useSiteMetadata()

  const toggleHamburger = () => setActive(!active)

  const notify=()=>{
    toast('Coming Soon!', {
      icon: '😀',
    });
  }

  const openShopify = () => window.open("https://apps.shopify.com/influencerbit?from=website","_blank")

  const signUp = () => window.open(dashboardRegisterUrl,"_blank")

  return (
    <div>
      <nav
        className="navbar navbarContainer"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container is-fluid nav-container">
          <div className="navbar-brand">
            <a
                href="/"
                className="logo-link"
            >
              <img
                  src={logo}
                  alt="navLogo"
                  className="image navbarImage"
                  loading="lazy"
                  title="navLogo"
              />
            </a>
            <span
              role="presentation"
              className={`header-nav is-vcentered navbar-burger burger ${
                active ? "is-active" : ""
              } has-text-black`}
              data-target="navMenu"
              onClick={() => toggleHamburger()}
              onKeyDown={()=>toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </span>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${active ? "is-active" : ""}`}
          >
            <div className="navbar-end is-vcentered is-size-5-tablet  has-text-centered ">
              <Link
                className="navbar-item navItem has-text-weight-bold nav-link is-size-5-desktop is-size-6-tablet is-size-6-mobile mt-2"
                to="/pricing"
              >
                Pricing
              </Link>
              <Link
                className="navbar-item navItem has-text-weight-bold nav-link is-size-5-desktop is-size-6-tablet is-size-6-mobile mt-2"
                to="/faq"
              >
                FAQs
              </Link>
              <a
                className="navbar-item navItem has-text-weight-bold nav-link is-size-5-desktop is-size-6-tablet is-size-6-mobile mt-2"
                href={"https://influencerbit.com/blogs"}
                target={"_blank"}
              >
                Blogs
              </a>
              <a
                className="navbar-item navItem has-text-weight-bold nav-link is-size-5-desktop is-size-6-tablet is-size-6-mobile mt-2"
                href={"https://help.influencerbit.com"}
                target={"_blank"}
              >
                Guides
              </a>
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${active ? "is-active" : ""}`}
          >
            <div className="navbar-end is-vcentered is-size-5-tablet has-text-centered">
              <a
                className="navbar-item navItem has-text-weight-bold nav-link is-size-5-desktop is-size-6-tablet is-size-6-mobile mt-2 navbarLogin"
                href="https://dashboard.influencerbit.com/login"
                target="_blank"
                rel="noreferrer"
              >
                LOG IN
              </a>
              <Link
                className="navbar-item navItemButton has-text-weight-bold nav-link is-size-5-desktop is-size-6-tablet is-size-6-mobile mt-2"
              >
                <button
                    className="button is-size-6-mobile tryItButton has-text-white is-medium-desktop is-small-tablet is-small-mobile"
                    onClick={openShopify}
                >
                  GET ON SHOPIFY
                </button>
              </Link>
            </div>
          </div>
        </div>
      </nav>
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  )
}
